<template>
  <div class="h-full w-full">
    <div
      class="absolute w-30 right-0 z-30  h-full w-full flex"
      :style="`background: url(${cover}) ; background-size: cover;`"
    >
      <div class="w-1/4"></div>
      <div
        v-show="isLoading"
        class="flex-grow flex items-center justify-center"
      >
        <vue-instant-loading-spinner
          ref="Spinner"
          spinnerStyle="clip-loader"
          color="#fff"
          size="80px"
        ></vue-instant-loading-spinner>
      </div>
      <div class="flex-grow w-3/4" v-show="!isLoading">
        <ul
          class="block my-4"
          style="max-height: 680px; overflow:auto; margin-right:70px"
        >
          <li
            v-for="(faq, idx) in faqs"
            class="flex align-center flex-col"
            :key="idx"
          >
            <h4
              class="cursor-pointer px-5 py-3 bg-black text-white bg-opacity-60 text-left inline-block hover:opacity-75 hover:shadow hover:-mb-3 rounded-t"
              @click="selected = idx"
            >
              {{ faq.title }}
            </h4>
            <p
              class="px-5 py-3 text-black bg-white bg-opacity-70 rounded-b"
              v-if="selected == idx"
              v-html="faq.text"
            ></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueInstantLoadingSpinner from 'vue-instant-loading-spinner'

export default {
  name: 'OptFaq',

  components: { VueInstantLoadingSpinner },

  data: () => ({
    cover: 'https://siou.nyc3.digitaloceanspaces.com/img/faq.jpeg',
    selected: null
  }),

  watch: {
    isLoading(val) {
      if (val) {
        this.$refs.Spinner.show()
      } else {
        this.$refs.Spinner.hide()
      }
    }
  },

  computed: {
    ...mapGetters('ui', {
      panels: 'getPanels'
    }),
    ...mapGetters('faq', {
      faqs: 'getFaqs',
      isLoading: 'getLoader'
    })
  },

  methods: {
    ...mapActions('faq', {
      fetchFaqs: 'fetchFaqs'
    })
  },

  mounted() {
    this.fetchFaqs()
  }
}
</script>

<style lang="scss" scoped></style>
