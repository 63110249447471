<template>
  <div class="w-full">
    <div
      class="card"
      v-for="(manufacturer, key) in manufactureres.flat()"
      :key="key"
      @click="setValue(manufacturer)"
    >
      <div v-html="manufacturer.label.toUpperCase()"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../boot/axios'
import _ from 'lodash'

export default {
  name: 'ProductManufacturer',

  computed: {
    ...mapGetters('menus', {
      manufactureres: 'getManufacturers'
    }),

    ...mapGetters('orders', {
      order: 'getNewOrder'
    }),

    type() {
      return this.order.type_id
    },

    manufacturer() {
      return this.order.manufacturer_id
    }
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchProducts', 'setTechnology']),

    ...mapActions('orders', ['setManufacturer']),

    async setValue(val) {
      await this.setManufacturer(val.value)
      axios
        .get('/lists/technologies', {
          params: {
            type_id: this.type,
            manufacturer_id: this.manufacturer
          }
        })
        .then(({ data }) => {
          this.$store.commit('menus/SET_TECHNOLOGY', _.chunk(data, 2))
        })
        .finally(() => {})

      this.setManufacturer(val.value)

      this.addPanel({
        ...val,
        action: 'removeManufacturer'
      })
    }
  }
}
</script>
