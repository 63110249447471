<template>
  <div v-if="!type" class="w-full mt-0">
    <div
      class="card mb-2"
      v-for="(type, key) in types.flat()"
      :key="key"
      @click="setValue(type)"
    >
      {{ type.label.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductType',

  computed: {
    ...mapGetters('menus', {
      types: 'getTypes',
      type: 'getType'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchType', 'fetchManufacturer']),
    ...mapActions('orders', ['setType']),

    async setValue(val) {
      this.setType(val.value)
      this.addPanel({
        ...val,
        action: 'removeType'
      })
    }
  },

  mounted() {
    this.fetchType()
    this.fetchManufacturer()
  }
}
</script>
