<template>
  <img
    :src="`https://siou.nyc3.digitaloceanspaces.com/img/logo-siou.svg`"
    alt=""
  />
</template>

<script>
export default {
  name: 'OptLogo',

  props: {
    width: {
      type: String,
      default: '30px'
    },
    color: {
      type: String,
      default: '35,31,32'
    }
  },

  computed: {
    style() {
      return `fill:rgb(${this.color});fill-rule:nonzero;`
    }
  }
}
</script>

<style lang="scss" scoped></style>
