import '@/assets/css/tailwind.css'
import 'izitoast/dist/css/iziToast.min.css'
import './registerServiceWorker'
import './boot/autoload.js'
import axiosInstance from './boot/axios.js'

import App from './App.vue'
import Loading from 'vue-loading-overlay'
import Vue from 'vue'
import router from './router'
import store from './store'
Vue.prototype.$http = axiosInstance
Vue.use(Loading)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
