<template>
  <div class="w-full">
    <div class="card">
      <label for="">Escolha a montagem</label>
      <select
        name="frameSelect"
        class="w-full text-white bg-transparent"
        v-model="hoop"
      >
        <option v-for="(hoop, key) in hoops.flat()" :key="key" :value="hoop">
          {{ hoop.label.toUpperCase() }} {{ currency(hoop.price) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductFrame',

  data: () => ({
    update: false,
    hoop: null
  }),

  computed: {
    ...mapGetters('menus', {
      hoops: 'getHoops'
    })
  },

  watch: {
    hoop(val) {
      this.setHoop(val.value)
      const data = {
        ...val,
        action: 'removeHoop'
      }
      data.label = `${val.label}<br/>${this.currency(val.price)}`
      if (this.update) {
        this.$store.commit('menus/UPDATE_PANEL', data)
      } else {
        this.update = true
        this.addPanel(data)
      }
    }
  },

  methods: {
    ...mapActions('menus', ['addPanel']),

    ...mapActions('orders', ['setHoop']),

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },

    setValue(hoop) {
      // val.label = `${val.label}<br/>${this.currency(val.price)}`
      console.log(hoop)
    }
  }
}
</script>
