<template>
  <div class="home">
    <transition name="slide">
      <OptSidebar v-if="panels.sidebar" />
    </transition>

    <OptMenu v-if="panels.menu" @changed="changePanels" />

    <transition name="fade">
      <OptCompareImages v-if="panels.compare" />
    </transition>

    <transition name="fade">
      <OptFaq v-if="panels.faq" />
    </transition>

    <transition name="fade">
      <OptSliderImage v-if="panels.slider" />
    </transition>

    <transition name="fade">
      <OptDrawer v-if="panels.drawer" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',

  computed: {
    ...mapGetters('ui', {
      panels: 'getPanels'
    })
  },

  methods: {
    ...mapActions('ui', {
      setMenus: 'setMenus'
    }),

    changePanels(data) {
      this.$store.commit('ui/SET_PANELS', { ...data.actions })
      this.setMenus(data.icons)

      if (data.icons.length > 0) {
        this.$store.commit('ui/SET_COVER', data.icons[0].cover)
      }
    }
  },

  beforeMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.$store.commit('auth/SET_USER_STATE', user)
    this.$store.commit('auth/SET_USER_PROFILE', user)
  }
}
</script>

<style lang="css">
.images {
  overflow: hidden;
}
</style>
