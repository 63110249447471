<template>
  <div class="w-full">
    <div
      class="card"
      v-for="(color, key) in colors.flat()"
      :key="key"
      @click="setValue(color)"
    >
      {{ color.label.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ProductColor',

  computed: {
    ...mapGetters('menus', {
      colors: 'getColors'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setColor']),

    async setValue(val) {
      this.setColor(val.value)

      const color = await this.colors
        .flat()
        .filter((colors) => {
          return colors.value === val.value
        })
        .shift()

      this.$store.commit('menus/SET_INDEX', _.chunk(color.indexes, 2))

      this.addPanel({
        ...val,
        action: 'removeColor'
      })
    }
  }
}
</script>
