<template>
  <div class="h-full w-full">
    <div class="absolute w-30 right-0 z-30 mt-4 mr-4">
      <OptInfoButton />
      <button @click="toggleDrawer">
        <img
          :src="
            `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-caneta.svg`
          "
          style="width: 24px;"
        />
      </button>
    </div>
    <div
      class="overflow-x-scroll overscroll-y-none h-screen w-screen"
      id="slider"
      @scroll="pan"
    >
      <div class="fixed flex items-center align-middle" :style="position">
        <svg
          height="400px"
          viewBox="0 0 88 54"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
          <g transform="matrix(1,0,0,1,-1.96633,-4.48336)">
            <g transform="matrix(1,0,0,1,1.96633,4.48336)">
              <path
                d="M26.636,52.636C25.209,52.392 23.632,52.113 22.054,51.814C18.826,51.198 15.7,
                50.135 12.765,48.657C9.472,47.17 6.867,44.483 5.483,41.146C4.43,38.49 3.514,35.783 2.736,33.035C0.991,
                26.517 0.085,19.804 0.039,13.057C-0.078,11.403 0.071,9.74 0.479,8.133C1.41,4.669 4.369,2.107 7.93,1.68C12.797,
                0.86 17.712,0.349 22.644,0.152C28.257,-0.098 33.871,-0.028 39.505,0.282C46.57,0.673 53.606,1.497 60.571,2.749C66.634,
                3.858 72.717,4.976 78.471,7.084C85.233,9.561 87.361,12.408 86.991,14.945C86.799,
                16.241 86.508,17.519 86.122,18.771C84.508,24.188 82.399,29.444 79.819,34.473C77.391,
                39.256 74.241,43.635 70.48,47.459C68.347,49.567 65.72,51.109 62.838,51.943C61.627,52.318 60.394,
                52.617 59.145,52.836C60.292,52.623 61.426,52.341 62.541,51.992C65.379,51.164 67.965,49.633 70.065,47.54C73.769,
                43.743 76.87,39.395 79.261,34.645C81.801,29.651 83.878,24.432 85.467,19.053C85.847,17.81 86.134,16.541 86.323,
                15.254C86.687,12.735 84.592,9.908 77.934,7.448C72.268,5.355 66.279,4.245 60.309,3.144C53.451,1.901 46.523,
                1.082 39.566,0.694C34.019,0.386 28.491,0.317 22.964,0.565C18.108,0.761 13.269,1.268 8.476,2.082C4.97,
                2.506 2.057,5.05 1.14,8.49C0.738,10.086 0.591,11.737 0.707,13.38C0.752,20.079 1.644,26.745 3.362,33.217C4.128,
                35.946 5.03,38.634 6.067,41.271C7.43,44.585 9.995,47.253 13.237,48.729C16.127,50.197 19.205,51.253 22.383,51.864C23.843,
                52.144 25.3,52.404 26.636,52.636Z"
                style="fill:url(#_Radial1);fill-rule:nonzero;"
              />
            </g>
            <g transform="matrix(0.984637,0,0,0.992977,0.698737,0.446068)">
              <path
                d="M2.445,12.616C3.376,9.152 6.335,6.59 9.896,6.163C14.763,5.343 19.678,4.832 24.61,4.635C30.223,
                4.385 35.837,4.455 41.471,4.765C48.536,5.156 55.572,5.98 62.537,7.232C68.6,8.341 74.683,9.459 80.437,
                11.567C87.199,14.044 89.327,16.891 88.957,19.428C88.765,20.724 88.474,22.002 88.088,23.254C86.474,
                28.671 84.365,33.927 81.785,38.956C79.357,43.739 76.207,48.118 72.446,51.942C70.313,54.05 67.686,
                55.592 64.804,56.426C63.386,56.866 61.937,57.2 60.469,57.425C58.222,57.755 55.934,57.945 53.667,
                58.125C46.787,58.641 39.874,58.551 33.01,57.855C31.162,57.545 27.596,56.976 24.02,56.297C20.792,
                55.681 17.666,54.618 14.731,53.14C11.438,51.653 8.833,48.966 7.449,45.629C6.396,42.973 5.48,
                40.266 4.702,37.518C2.957,31 2.051,24.287 2.005,17.54C1.888,15.886 2.037,14.223 2.445,12.616Z"
                style="fill:url(#_Linear2);fill-rule:nonzero;"
              />
            </g>
          </g>
          <defs>
            <radialGradient
              id="_Radial1"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(87.0309,0,0,52.8357,-1.5892e-06,26.4179)"
            >
              <stop
                offset="0"
                :style="
                  `stop-color:rgb(${color},${color},${color});stop-opacity:${opacity +
                    0.39}`
                "
              />
              <stop
                offset="1"
                :style="
                  `stop-color:rgb(${color},${color},${color});stop-opacity:${opacity +
                    0.38}`
                "
              />
            </radialGradient>
            <linearGradient
              id="_Linear2"
              x1="0"
              y1="0"
              x2="1"
              y2="0"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(87.0309,0,0,53.9712,1.96633,31.4689)"
            >
              <stop
                offset="0"
                :style="
                  `stop-color:rgb(${color},${color},${color});stop-opacity:${opacity +
                    0.08}`
                "
              />
              <stop
                offset="0.43"
                :style="
                  `stop-color:rgb(${color},${color},${color});stop-opacity:${opacity +
                    0.06}`
                "
              />
              <stop
                offset="1"
                :style="
                  `stop-color:rgb(${color},${color},${color});stop-opacity:${opacity}`
                "
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <img
        id="scrollImage"
        :src="
          `https://siou.nyc3.digitaloceanspaces.com/img/buttons/cena-lente-photo-panoramica.jpg`
        "
        style="max-width:2651px;"
        class="h-screen"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptSliderImage',

  data: () => ({
    drawer: true,
    opacity: 0.08,
    color: 0
  }),

  computed: {
    style() {
      return 'margin:0 auto;width: 400px;'
    },

    position() {
      return `top: 50%; left: 50%;height:250px;margin:0 auto;transform: translate(-50%, -50%);`
    }
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },

    pan() {
      let el = document.getElementById('slider')
      let screen = el.scrollLeft
      const number = screen / 100 / 100
      const calc = parseFloat(number.toFixed(2))

      if (calc === 0.01) this.opacity = 0.1
      if (calc === 0.02) this.opacity = 0.15
      if (calc === 0.03) this.opacity = 0.2
      if (calc === 0.04) this.opacity = 0.25
      if (calc === 0.05) this.opacity = 0.3
      if (calc === 0.06) this.opacity = 0.35
      if (calc === 0.07) this.opacity = 0.4
      if (calc === 0.08) this.opacity = 0.45
      if (calc === 0.09) this.opacity = 0.5
      if (calc === 0.11) this.opacity = 0.6
      if (calc === 0.12) this.opacity = 0.65
      if (calc === 0.13) this.opacity = 0.7
      if (calc === 0.14) this.opacity = 0.75
      if (calc === 0.15) this.opacity = 0.8
      console.log(calc)
    }
  }
}
</script>

<style lang="css" scoped>
.imageBG {
  background-size: 2470px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lens {
  width: 400px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
}
#slider::-webkit-scrollbar {
  display: none;
}
#slider {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
