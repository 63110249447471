<template>
  <button
    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    style="background: rgb(37 108 98)"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'OptButton',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
