<template>
  <div class="w-full">
    <div
      class="card"
      v-for="(technology, key) in technologies.flat()"
      :key="key"
      @click="setValue(technology)"
    >
      {{ technology.label.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import axios from '../../boot/axios'

export default {
  name: 'ProductTechnology',

  computed: {
    ...mapGetters('menus', {
      technologies: 'getTechnologies',
      loader: 'getLoader'
    }),

    ...mapGetters('orders', {
      order: 'getNewOrder'
    })
  },

  methods: {
    ...mapActions('menus', [
      'addPanel',
      'setColors',
      'fetchProducts',
      'fetchIndex'
    ]),
    ...mapActions('orders', ['setTechnology', 'setColor']),

    async setValue(val) {
      await this.setTechnology(val.value)
      if (val.colors.length === 0) {
        this.setColor(0)
        axios
          .get('/lists/indexes', {
            params: {
              technology_id: this.order.technology_id,
              type_id: this.order.type_id,
              manufacturer_id: this.order.manufacturer_id
            }
          })
          .then(({ data }) => {
            this.$store.commit('menus/SET_INDEX', _.chunk(data, 2))
          })
          .finally(() => {})
      } else {
        axios
          .get('lists/colors', {
            params: { technology: val.value }
          })
          .then(async ({ data }) => {
            const colors = await data[0].colors.map((item) => ({
              value: item.id,
              label: item.name,
              indexes: item.indexes.map((item) => ({
                label: item.name,
                value: item.id
              }))
            }))

            this.$store.commit('menus/SET_COLOR', _.chunk(colors, 2))
          })
          .finally(() => {})
      }

      this.addPanel({
        ...val,
        action: 'removeTechnology'
      })
    }
  }
}
</script>
