<template>
  <div class="text-center">
    <button @click="back">
      <arrow-left-circle-icon
        size="1.5x"
        class="color-white mx-auto"
      ></arrow-left-circle-icon>
      {{ text }}
    </button>
  </div>
</template>

<script>
import { ArrowLeftCircleIcon } from 'vue-feather-icons'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OptBackwardButton',

  props: {
    text: {
      type: String,
      default: 'Anterior'
    }
  },

  components: {
    ArrowLeftCircleIcon
  },

  computed: {
    ...mapGetters('ui', {
      before: 'getBeforeMenus'
    })
  },

  methods: {
    ...mapActions('ui', {
      setMenus: 'setMenus'
    }),

    back() {
      if (this.$route.name === 'Budget') {
        this.$router.push('/home')
      } else {
        this.$store.commit('ui/DECREMENT_STEP')
        const menu = JSON.parse(JSON.stringify(this.before))
        this.setMenus(menu)
        this.$store.commit('ui/SET_COMPARE', {
          before: null,
          after: null
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
