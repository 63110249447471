<template>
  <div class="text-center">
    <arrow-right-circle-icon
      size="1.5x"
      class="color-white mx-auto"
    ></arrow-right-circle-icon>
    {{ text }}
  </div>
</template>

<script>
import { ArrowRightCircleIcon } from 'vue-feather-icons'

export default {
  name: 'OptFowardButton',

  props: {
    text: {
      type: String,
      default: 'Próximo'
    }
  },

  components: {
    ArrowRightCircleIcon
  }
}
</script>

<style lang="css" scoped>
button {
  width: 30px;
}
</style>
