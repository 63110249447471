<template>
  <div class="h-1/6 flex justify-around items-end" v-if="logo">
    <img :src="logo" />
  </div>
</template>

<script>
export default {
  name: 'OptLogoSidebarFooter',

  data: () => ({
    logo: false
  }),

  methods: {
    async getLogo() {
      this.logo = await localStorage.getItem('logo')
    }
  },

  mounted() {
    this.getLogo()
  }
}
</script>

<style lang="css" scoped>
.logoImage {
}
</style>
