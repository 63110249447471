<template>
  <div>
    <OptHomeButton class="absolute top-6 left-6 " style="z-index:1000" />
    <LensImage
      :diopter="diopter"
      :index="leftIndex"
      :measures="measures"
      v-if="measures.length > 0"
    />
    <LensImage
      v-if="measures.length > 0"
      :position="'RIGHT'"
      :diopter="diopter"
      :index="rightIndex"
      :measures="measures"
    />
    <CoverImages />
    <div
      class="flex absolute bottom-0 w-full bg-black bg-opacity-40"
      v-if="measures.length > 0"
    >
      <div
        class="bg-dark-500 bg-opacity-40 w-full flex items-center justify-evenly"
        style="height: 160px"
      >
        <IndexChooser
          :diopter="diopter"
          @choose="setRightIndex"
          :index="'1.74'"
        />
        <DiopterChooser @input="setDiopter" />
        <IndexChooser
          :diopter="diopter"
          @choose="setLeftIndex"
          :index="'1.50'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../boot/axios'

export default {
  name: 'Measure',

  data: () => ({
    diopter: '0',
    rightIndex: '1.50',
    leftIndex: '1.74',
    measures: []
  }),

  methods: {
    setDiopter(val) {
      this.diopter = val.value
    },

    setRightIndex(val) {
      this.leftIndex = val.value
    },

    setLeftIndex(val) {
      this.rightIndex = val.value
    },

    async getMeasures() {
      const { data } = await axiosInstance.get('/measures')
      this.measures = data
    }
  },

  mounted() {
    this.getMeasures()
  }
}
</script>

<style lang="scss" scoped></style>
