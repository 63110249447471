export function getTypes(state) {
  return state.type
}

export function getIndexes(state) {
  return state.index
}

export function getCompare(state) {
  return state.compare
}

export function getTechnologies(state) {
  return state.technology
}

export function getColors(state) {
  return state.color
}

export function getManufacturers(state) {
  return state.manufacturer
}

export function getTreatments(state) {
  return state.treatment
}

export function getHoops(state) {
  return state.hoop
}

export function getCategories(state) {
  return state.category
}

export function getProducts(state) {
  return state.products
}

export function getLoader(state) {
  return state.loading
}

export function getApis(state) {
  return state.apis
}

export function hasProducts(state) {
  return state.has_products
}

export function getPanels(state) {
  return state.panels
}

export function getType(state) {
  const type = state.panels.filter((type) => type.action === 'removeType')

  if (type.length > 0) {
    return true
  }

  return false
}

export function getTechnology(state) {
  const tech = state.panels.filter((tech) => tech.action === 'removeTechnology')

  if (tech.length > 0) {
    return true
  }

  return false
}

export function getIndex(state) {
  const tech = state.panels.filter((tech) => tech.action === 'removeIndex')

  if (tech.length > 0) {
    return true
  }

  return false
}

export function getColor(state) {
  const tech = state.panels.filter((tech) => tech.action === 'removeColor')

  if (tech.length > 0) {
    return true
  }

  return false
}

export function getHoop(state) {
  const hoop = state.panels.filter((hoop) => hoop.action === 'removeHoop')

  if (hoop.length > 0) {
    return true
  }

  return false
}

export function getTreatment(state) {
  const hoop = state.panels.filter((hoop) => hoop.action === 'removeTreatment')

  if (hoop.length > 0) {
    return true
  }

  return false
}

export function getManufacturer(state) {
  const manufacturer = state.panels.filter(
    (manufacturer) => manufacturer.action === 'removeManufacturer'
  )

  if (manufacturer.length > 0) {
    return true
  }

  return false
}
