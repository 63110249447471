export default function() {
  return {
    isLoading: false,
    icons: [],
    gallery: null,
    menus: [],
    before_menus: [],
    after_menus: [],
    sidebar: null,
    cover: null,
    info_image: null,
    step: 0,
    panels: {
      menu: true,
      sidebar: false,
      compare: false,
      slider: false,
      drawer: false
    },
    compare: {
      before: null,
      after: null
    }
  }
}
