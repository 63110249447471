<template>
  <div>
    <OptLogoffButton class="absolute bottom-10 left-20 text-white" />
    <div
      class="w-screen h-screen space-x-6 flex items-center justify-center"
      :style="coverStyle"
      v-if="icons.length > 0"
    >
      <div style="width:850px;" class="flex flex-wrap justify-center flex-row">
        <button
          v-for="(item, k) in icons"
          :key="k"
          class="border b-color-white py-5 px-5 rounded-lg bg-black bg-opacity-40 m-2"
          @click="setAndChangeMenu(item)"
        >
          <img :src="item.icon" class="w-28" />
        </button>
        <button
          class="border b-color-white py-5 px-5 rounded-lg bg-black bg-opacity-40 m-2"
          @click="$router.push('/budget')"
        >
          <img
            :src="
              `https://siou.nyc3.digitaloceanspaces.com/img/buttons/menu_1_4.svg`
            "
            class="w-28"
          />
        </button>
        <button
          class="border b-color-white py-5 px-5 rounded-lg bg-black bg-opacity-40 m-2"
          @click="$router.push('/measure')"
        >
          <img
            :src="
              `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-index.svg`
            "
            class="w-28"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OptMenu',

  computed: {
    ...mapGetters('ui', {
      loading: 'isLoading',
      icons: 'getIcons'
    }),
    ...mapGetters('auth', {
      user: 'getUser'
    }),

    store() {
      return this.user.store
    },

    cover() {
      return this.icons[0].cover
    },

    coverStyle() {
      return `background: url(https://siou.nyc3.digitaloceanspaces.com/capa/${this.store.id}.jpeg); 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;`
    }
  },

  methods: {
    ...mapActions('ui', {
      fetchMenus: 'fetchInterface'
    }),

    setAndChangeMenu(payload) {
      this.$emit('changed', payload)
      this.$store.commit('ui/INCREMENT_STEP')
    }
  },

  async beforeMount() {
    if (this.icons.length === 0) {
      await this.fetchMenus()
    }
  }
}
</script>

<style lang="scss" scoped></style>
