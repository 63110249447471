import _ from 'lodash'
import axios from '../../boot/axios'

export function fetchTechnology({ commit }) {
  commit('SET_LOADER', true)
  axios
    .get('/technologies')
    .then(({ data }) => {
      const list = data.map((item) => ({
        value: item.id,
        label: item.name,
        colors: item.colors ?? [],
        has_products: item.has_products
      }))

      commit('SET_TECHNOLOGY', _.chunk(list, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function setProductsVisibility({ commit }, payload) {
  commit('SET_HAS_PRODUCTS', payload)
}

export function fetchType({ commit }) {
  commit('SET_LOADER', true)
  axios
    .get('/types')
    .then(({ data }) => {
      const list = data.map((item) => ({
        value: item.id,
        label: item.name,
        has_products: item.has_products,
        technologies: _.chunk(item.technologies, 2)
      }))

      commit('SET_TYPE', _.chunk(list, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchApis({ commit }) {
  commit('SET_LOADER', true)
  axios
    .get('/apis')
    .then(({ data }) => {
      const apis = data.map((lab) => ({
        value: lab.id,
        label: `${lab.laboratory.name} (${lab.description})`
      }))
      commit('SET_APIS', apis)
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchManufacturer({ commit }) {
  commit('SET_LOADER', true)
  axios
    .get('/manufacturers', { params: { all: 1 } })
    .then(({ data }) => {
      const list = data.map((item) => ({
        value: item.id,
        label: item.name
      }))

      commit('SET_MANUFACTURER', _.chunk(list, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchIndex({ commit, rootState }) {
  const { type_id, technology_id, manufacturer_id } = rootState.orders.newOrder
  commit('SET_LOADER', true)
  axios
    .get('/indexes', {
      params: {
        type_id,
        technology_id,
        manufacturer_id,
        all: 1
      }
    })
    .then(({ data }) => {
      const list = data.map((item) => ({
        value: item.id,
        label: item.name
      }))

      const colors = data.map((item) => item.colors).flat()

      let selected = _.uniqBy(colors, 'name').map((item) => ({
        value: item.id,
        label: item.name
      }))

      commit('SET_INDEX', _.chunk(list, 2))
      commit('SET_COLOR', _.chunk(selected, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchTreatment({ commit }) {
  commit('SET_LOADER', true)
  axios
    .get('/treatments', { params: { all: 1 } })
    .then(({ data }) => {
      const list = data.map((item) => {
        const val = (item.price / 1).toFixed(2).replace('.', ',')
        const price = `R$ ${val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`

        return {
          value: item.id,
          label: `${item.name} <br/> ${price}`,
          price: item.price
        }
      })

      commit('SET_TREATMENT', _.chunk(list, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchHoop({ commit }, payload) {
  commit('SET_LOADER', true)
  let params = { params: { all: 1 } }
  if (payload) {
    params.params.product_id = payload
  }
  axios
    .get('/hoops', params)
    .then(({ data }) => {
      const list = data.map((item) => ({
        value: item.id,
        label: item.name,
        price: parseFloat(item.price)
      }))

      commit('SET_HOOP', _.chunk(list, 2))
    })
    .finally(() => commit('SET_LOADER', false))
}

export function fetchProducts({ commit, rootState }) {
  commit('SET_LOADER', true)
  commit('SET_PRODUCTS', [])
  const { newOrder } = rootState.orders
  let params = { ...newOrder, all: 1 }

  delete params.data
  delete params.prescription
  delete params.product_id
  delete params.remark
  delete params.frame
  delete params.individualParameters
  delete params.customer
  delete params.treatment_id
  delete params.hoop_id
  delete params.lens_id
  delete params.doctor

  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  )

  axios
    .get('/products', {
      params: params
    })
    .then(({ data }) => {
      commit(
        'SET_PRODUCTS',
        data.map((item) => ({ ...item }))
      )
    })
    .finally(() => commit('SET_LOADER', false))
}

export function setColors({ commit, state }, id) {
  const items = _.flatten(state.technology)
  const techs = items.filter((tech) => tech.value === id)
  const list = techs[0].colors.map((item) => ({
    value: item.id,
    label: item.name
  }))

  commit('SET_COLOR', _.chunk(list, 3))
}

export function addPanel({ commit }, payload) {
  commit('PUSH_PANELS', payload)
}

export function removePanel({ commit }, payload) {
  commit('REMOVE_PANEL', payload)
  if (payload.action === 'removeType') {
    commit('orders/UPDATE_TYPE', null, { root: true })
  }
  if (payload.action === 'removeTechnology') {
    commit('orders/UPDATE_TECHNOLOGY', null, { root: true })
  }
  if (payload.action === 'removeIndex') {
    commit('orders/UPDATE_INDEX', null, { root: true })
  }
  if (payload.action === 'removeColor') {
    commit('orders/UPDATE_COLOR', null, { root: true })
  }
  if (payload.action === 'removeTreatment') {
    commit('orders/UPDATE_TREATMENT', null, { root: true })
  }
  if (payload.action === 'removeManufacturer') {
    commit('orders/UPDATE_MANUFACTURER', null, { root: true })
  }
  if (payload.action === 'removePrism') {
    commit('orders/SET_PRISM', false, { root: true })
  }
}
