<template>
  <div class="text-white" style="min-width: 400px">
    <div class="w-full text-center text-xl">índice</div>
    <div class="flex justify-around w-full">
      <div v-for="(index, idx) in indexes" :key="idx" @click="setIndex(idx)">
        <div
          :class="
            `text-center item text-lg py-1 cursor-pointer p-2 rounded-md ${
              index.active ? 'ativo' : ''
            }`
          "
        >
          {{ index.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexChooser',

  props: ['diopter', 'index'],

  data: () => ({
    indexes: [
      {
        value: '1.50',
        active: false
      },
      {
        value: '1.53',
        active: false
      },
      {
        value: '1.56',
        active: false
      },
      {
        value: '1.59',
        active: false
      },
      {
        value: '1.60',
        active: false
      },
      {
        value: '1.67',
        active: false
      },
      {
        value: '1.74',
        active: false
      }
    ]
  }),

  methods: {
    setIndex(idx) {
      this.indexes.forEach((item) => (item.active = false))
      this.indexes[idx].active = true
      this.$emit('choose', this.indexes[idx])
    }
  },

  mounted() {
    if (this.index) {
      this.indexes.forEach((item, idx) => {
        if (item.value === this.index) {
          this.indexes[idx].active = true
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.ativo {
  @apply bg-black bg-opacity-50;
}
</style>
