export default function() {
  return {
    has_products: false,
    loading: false,
    type: [],
    index: [],
    technology: [],
    color: [],
    manufacturer: [],
    treatment: [],
    hoop: [],
    category: [],
    products: [],
    panels: [],
    compare: [],
    apis: []
  }
}
