<template>
  <div class="w-full">
    <div class="card">
      <label data-v-682afef3="" for="">Escolha o tratamento</label>
      <select
        name="frameSelect"
        class="w-full text-white bg-transparent"
        v-model="treatment"
      >
        <option
          v-for="(treatment, key) in treatments.flat()"
          :key="key"
          :value="treatment"
        >
          {{ removeBr(treatment.label.toUpperCase()) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductTreatment',

  data: () => ({
    update: false,
    treatment: null
  }),

  computed: {
    ...mapGetters('menus', {
      treatments: 'getTreatments'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel']),
    ...mapActions('orders', ['setTreatment']),

    removeBr(val) {
      return val.replace('<BR/>', '')
    },

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    }
  },

  watch: {
    treatment(val) {
      this.setTreatment(val.value)
      const data = {
        ...val,
        action: 'removeTreatment'
      }
      // data.label = `${val.label}<br/>${this.currency(val.price)}`
      if (this.update) {
        this.$store.commit('menus/UPDATE_PANEL', data)
      } else {
        this.update = true
        this.addPanel(data)
      }
    }
  }
}
</script>
