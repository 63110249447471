<template>
  <div
    class="h-full bg-black absolute w-1/6 fixed z-50 bg-opacity-40 text-white flex-col flex-grow md:flex-row z"
  >
    <div class="flex justify-around h-1/6 items-center">
      <OptHomeButton />
      <OptBackwardButton v-if="1 == 2" />
      <OptFowardButton v-if="$route.name !== 'Budget' && 1 == 2" />
    </div>
    <div :class="iconsListClass" style="overflow-y: scroll">
      <!-- TODO:: deixar botão ativo destacado -->
      <button
        v-for="(item, k) in menus"
        :key="k"
        @click="setMenu(item, k)"
        class="border-none outline-none sidebarButton"
      >
        <img :src="item.icon" class="mx-auto imgButton" />
        <span v-if="item.title" class="text-sm" v-html="item.title"></span>
      </button>
    </div>
    <OptLogoSidebarFooter />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OptSidebar',

  data: () => ({
    icons: []
  }),

  computed: {
    ...mapGetters('ui', {
      menus: 'getMenus'
    }),

    store() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.store
      }
      return false
    },

    iconsListClass() {
      return {
        'h-4/6': true,
        'flex-5': true,
        flex: true,
        'flex-col': true,
        'items-center': true,
        'space-y-4': false,
        'overflow-y-auto': false
      }
    }
  },

  methods: {
    ...mapActions('ui', {
      setMenus: 'setMenus'
    }),

    activateButton(k) {
      const buttons = document.getElementsByClassName('imgButton')
      for (let button of buttons) {
        button.classList.remove('active')
      }
      buttons[k].classList.add('active')
    },

    setMenu(item, k) {
      this.activateButton(k)
      this.$store.commit('ui/SET_INFO_IMAGE', item.info_image)
      if (item.icons) {
        let beforeMenu = JSON.parse(JSON.stringify(this.menus))
        this.$store.commit('ui/SET_BEFORE_MENUS', beforeMenu)
        this.$store.commit('ui/INCREMENT_STEP')
        this.setMenus(item.icons)
      } else {
        this.$store.commit('ui/SET_GALLERY', item.gallery)
        this.$store.commit('ui/SET_COMPARE', item.images)
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      const buttons = Array.from(
        document.getElementsByClassName('sidebarButton')
      )

      buttons.forEach((button, idx) => {
        if (idx === 0) {
          button.click()
        }
      })
    })
  }
}
</script>

<style lang="css">
.imgButton {
  width: 80px;
}
.active {
  width: 110px;
  border: none;
  filter: drop-shadow(0px 0px 20px #fff);
}

button:focus {
  outline: 0 !important;
}
</style>
