<template>
  <button @click="resetState">
    <img
      :src="
        `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-redefinir.svg`
      "
      alt=""
    />
  </button>
</template>

<script>
import { mapActions } from 'vuex'

const rightEye = {
  addition: 0,
  axis: 0,
  cylindrical: 0,
  fittingHeight: 0,
  horizontalPrismAxis: 'NASAL',
  horizontalPrismValue: 0,
  interPupillaryDistance: 0,
  itemSequence: '1',
  side: 'RIGHT',
  spherical: 0,
  verticalPrismAxis: 'UP',
  verticalPrismValue: 0,
  curvature: 0,
  pantographic: 0,
  eyecode: 0,
  cvp: 0,
  sphericalNear: 0
}

const leftEye = {
  addition: 0,
  axis: 0,
  cylindrical: 0,
  fittingHeight: 0,
  horizontalPrismAxis: 'NASAL',
  horizontalPrismValue: 0,
  interPupillaryDistance: 0,
  itemSequence: '1',
  side: 'LEFT',
  spherical: 0,
  verticalPrismAxis: 'UP',
  verticalPrismValue: 0,
  curvature: 0,
  pantographic: 0,
  eyecode: 0,
  cvp: 0,
  sphericalNear: 0
}

const order = {
  send_to: false,
  orders: null,
  total: 0,
  order: null,
  hasPrism: false,
  hasMark: false,
  newOrder: {
    type_id: null,
    index_id: null,
    lens_id: null,
    technology_id: null,
    color_id: null,
    manufacturer_id: null,
    treatment_id: null,
    product_id: null,
    hoop_id: null,
    prescription: {
      right_eye: rightEye,
      left_eye: leftEye
    },
    customer: {
      email: null,
      document: null,
      name: null,
      phone: null,
      type: null
    },
    frame: {
      vBox: null,
      hBox: null,
      distanceBetweenLenses: null,
      largestDiameter: null,
      assemblyDiameter: null,
      largerDiagonal: null
    },
    individualParameters: {
      dominantEye: 'D',
      vmap: null
    },
    remark: null,
    doctor: {
      name: false,
      document: false
    }
  }
}

export default {
  name: 'OptResetOrderButton',

  methods: {
    ...mapActions('menus', ['fetchType', 'fetchManufacturer']),
    ...mapActions('orders', ['setIndex', 'setProduct']),

    resetState() {
      window.location.reload()
    }
  }
}
</script>

<style lang="css" scoped>
button {
  width: 30px;
}
</style>
