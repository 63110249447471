<template>
  <div class="w-full mt-0">
    <div class="card px-4 mb-0">
      <label for="">Escolha o produto</label>
      <select v-model="selected" class="w-full text-white bg-transparent mt-0">
        <option
          v-for="(item, k) in products"
          :key="k"
          :value="item"
          v-html="item.label"
        >
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductList',

  data: () => ({
    title: 'ESCOLHA O PRODUTO',
    id: 'type_id',
    selected: null
  }),

  watch: {
    selected(val) {
      this.setValue(val)
      this.$emit('input', val)
    }
  },

  computed: {
    ...mapGetters('menus', {
      show: 'hasProducts',
      products: 'getProducts',
      loading: 'getLoader'
    }),

    ...mapGetters('orders', {
      order: 'getNewOrder'
    })
  },

  methods: {
    ...mapActions('orders', ['setProduct']),
    ...mapActions('menus', ['fetchHoop', 'addPanel', 'removePanel']),

    setValue(val) {
      this.removePanel({
        ...val,
        action: 'removeProduct'
      })

      this.addPanel({
        ...val,
        action: 'removeProduct'
      })

      this.setProduct(val)
      this.fetchHoop(val.value)
    }
  }
}
</script>

<style lang="css" scoped>
.orverlay {
  position: absolute;
  background: #00000094;
  width: 100%;
  height: 100%;
  z-index: 3 !important;
  top: 0;
  right: 0;
}
</style>
