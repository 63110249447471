<template>
  <div :class="templateClass">
    <div class="bg-gray-100 w-2/5 flex items-center justify-center">
      <LoginForm />
    </div>
    <div
      class="h-full w-3/5"
      :style="
        `background:url(${coverImage});background-size: cover;background-position: center center;`
      "
    ></div>
  </div>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue'

export default {
  name: 'LoginView',

  components: {
    LoginForm
  },

  data: () => ({
    coverImage: `https://siou.nyc3.digitaloceanspaces.com/img/tela.jpg`,
    templateClass: {
      flex: true,
      'flex-direction': true,
      'h-screen': true,
      'content-around': true,
      'w-screen': true,
      'items-stretch': true,
      'justify-items-auto': true
    }
  })
}
</script>

<style lang="css" scoped></style>
