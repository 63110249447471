<template>
  <div class="card" v-if="panels.length > 0">
    <div v-for="(panel, key) in panels" :key="key" class="mb-2">
      <div v-html="panel.label.toUpperCase()"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductPanel',

  computed: {
    ...mapGetters({ panels: 'menus/getPanels' })
  },

  methods: {
    ...mapActions('menus', { remove: 'removePanel' })
  }
}
</script>
