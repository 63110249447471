import axios from '../../boot/axios'

export function fetchFaqs({ commit }, payload) {
  commit('SET_LOADING', true)
  axios
    .get('/faqs', payload)
    .then(({ data }) => {
      commit('SET_FAQS', data)
    })
    .catch(() => {})
    .finally(() => commit('SET_LOADING', false))
}
