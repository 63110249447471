<template>
  <div class="h-full w-full">
    <OptGallerySwitcher />
    <div class="absolute w-30 right-0 mt-4 mr-4">
      <OptInfoButton class="z-50 absolute" />
      <button @click="toggleDrawer" v-if="after">
        <img
          :src="
            `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-caneta.svg`
          "
          style="width: 24px;"
        />
      </button>
    </div>
    <OptDrawer :image="after" />
    <div>
      <div v-if="!before && !after" class="h-screen w-full bg"></div>
      <VueCompareImage
        v-else
        :before="before"
        :after="after"
        :sliderPositionPercentage="0.6"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OptCompareImages',

  data: () => ({
    drawer: false
  }),

  computed: {
    ...mapGetters('ui', {
      images: 'getCompareImages',
      images: 'getCompareImages',
      gallery: 'getGallery'
    }),

    before() {
      return this.images.before
    },

    after() {
      return this.images.after
    }
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style lang="scss" scoped></style>
