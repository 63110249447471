<template>
  <div class="h-screen w-screen absolute z-20">
    <a href="#" :class="buttonClass" @click.prevent.stop="toggleDrawer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 512 512"
        fill="white"
      >
        <polygon
          points="103 464 48 464 48 409 358.14 98.09 413.91 153.87 103 464"
        />
        <path
          d="M425.72,142,370,86.28l31.66-30.66C406.55,50.7,414.05,48,421,48a25.91,25.91,0,0,1,18.42,7.62l17,17A25.87,25.87,0,0,1,464,91c0,7-2.71,14.45-7.62,19.36ZM418.2,71.17h0Z"
        />
      </svg>
    </a>
    <a
      href="#"
      class="absolute right-2 top-20 z-50 block rounded-full p-2"
      v-if="erase && isOpen"
      @click.prevent.stop="clear"
    >
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M11.7195 8.04001C11.6414 7.9619 11.5148 7.9619 11.4367 8.04001L8.44546 11.0312L11.5 
          14.0858L14.4912 11.0945C14.5693 11.0164 14.5693 10.8898 14.4912 10.8117L11.7195 
          8.04001ZM10.0858 15.5L7.03125 12.4455L5.08688 14.3898C5.00878 14.4679 5.00878 14.5946 
          5.08688 14.6727L7.85858 17.4444C7.93668 17.5225 8.06332 17.5225 8.14142 17.4444L10.0858 
          15.5ZM10.0225 6.62579C10.8816 5.76664 12.2746 5.76664 13.1338 6.6258L15.9055 
          9.39749C16.7646 10.2566 16.7646 11.6496 15.9055 12.5088L9.55563 18.8586C9.50558 
          18.9086 9.45371 18.9558 9.40024 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 
          21 20 21H4C3.44772 21 3 20.5523 3 20C3 19.4477 3.44772 19 4 19H6.59976C6.54629 18.9558 
          6.49442 18.9086 6.44436 18.8586L3.67267 16.0869C2.81352 15.2277 2.81352 13.8348 3.67267 
          12.9756L10.0225 6.62579Z"
          fill="white"
          fill-rule="evenodd"
        />
      </svg>
    </a>
    <canvas
      v-show="isOpen"
      id="myCanvas"
      class="absolute w-screen h-screen"
      :width="width"
      :height="height"
      @mousedown="beginDrawing"
    />
  </div>
</template>

<script>
import { create } from 'simple-drawing-board'

export default {
  name: 'OptCompareImages',

  props: {
    image: {
      type: String
    }
  },

  data: () => ({
    isOpen: false,
    erase: false,
    canvas: null,
    x: 0,
    y: 0,
    height: window.innerHeight,
    width: window.innerWidth
  }),

  computed: {
    buttonClass() {
      return {
        absolute: true,
        'right-3': true,
        'top-12': true,
        'z-50': true,
        block: true,
        'rounded-full': true,
        'p-2': true,
        'bg-gray-800': this.isOpen,
        'bg-opacity-50': this.isOpen
      }
    }
  },

  methods: {
    beginDrawing() {
      this.erase = true
    },

    toggleDrawer() {
      this.isOpen = !this.isOpen
      this.erase = !this.erase
      this.$emit('open', true)
    },

    clear() {
      this.canvas.clear()
    }
  },

  mounted() {
    this.canvas = create(document.getElementById('myCanvas'))
    this.canvas.setLineSize(4)
    this.canvas.setLineColor('#e40000')
  }
}
</script>

<style lang="css" scoped>
#sheet {
  border: 1px solid black;
}
</style>
