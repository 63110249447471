export function SET_TECHNOLOGY(state, payload) {
  state.technology = payload
}

export function SET_TYPE(state, payload) {
  state.type = payload
}

export function SET_MANUFACTURER(state, payload) {
  state.manufacturer = payload
}

export function SET_INDEX(state, payload) {
  state.index = payload
}

export function SET_TREATMENT(state, payload) {
  state.treatment = payload
}

export function SET_HOOP(state, payload) {
  state.hoop = payload
}

export function SET_PRODUCTS(state, payload) {
  state.products = payload
}

export function SET_COLOR(state, payload) {
  state.color = payload
}

export function SET_LOADER(state, payload) {
  state.loading = payload
}

export function SET_HAS_PRODUCTS(state, payload) {
  state.has_products = payload
}
export function CLEAR_PANELS(state) {
  state.panels = []
}
export function CLEAR_COMPARE(state) {
  state.compare = []
}

export function PUSH_PANELS(state, payload) {
  state.panels.push(payload)
}

export function SET_APIS(state, payload) {
  state.apis = payload
}
export function SET_COMPARE(state, payload) {
  state.compare.push(payload)
}

export function RESET_PANELS(state) {
  state.panels = []
  // state.manufacturer = []
  state.index = []
  state.technology = []
  state.treatment = []
}

export function REMOVE_PANEL(state, payload) {
  state.panels.filter((panel, key) => {
    if (panel.action === payload.action) {
      state.panels.splice(key, 1)
    }
  })
}

export function UPDATE_PANEL(state, payload) {
  let position = null
  state.panels.forEach((item, idx) => {
    if (item.action === payload.action) {
      position = idx
    }
  })

  let panels = JSON.parse(JSON.stringify(state.panels))

  panels[position] = payload

  state.panels = panels
}
