<template>
  <div :style="containerClass" :ref="this.position">
    <img
      v-if="isNegative"
      src="https://siou.nyc3.digitaloceanspaces.com/img/dioptria-negativa.png"
      :style="imgClass"
      :id="position"
    />
    <img
      v-if="!isNegative"
      src="https://siou.nyc3.digitaloceanspaces.com/img/dioptria-positiva.png"
      :style="imgClass"
      :id="position"
    />
  </div>
</template>

<script>
export default {
  name: 'LensImage',

  props: {
    diopter: {
      type: [Number, String]
    },
    measures: {
      type: [Array, Object]
    },
    index: {
      type: [Number, String]
    },
    position: {
      type: String,
      default: 'LEFT'
    }
  },

  computed: {
    isNegative() {
      return this.diopter.includes('-')
    },

    width() {
      let width = 100
      const diopter = this.measures
        .filter((item) => item.diopter == this.diopter.replace('-', ''))
        .slice(0, 1)
        .shift()
      // console.log(this.$refs)
      // console.log(diopter, this.index)

      // width = 3 * diopter.indexes[this.index]

      // console.log(width)

      return `100%`
    },

    screenWidth() {
      return window.screen.availWidth
    },

    screenHeight() {
      return window.screen.availHeight
    },

    top() {
      if (this.screenHeight === 800) {
        return 22
      }
      if (this.screenHeight === 768) {
        return 21
      }
      if (this.screenHeight === 900) {
        return 21
      }
      if (this.screenHeight === 720) {
        return 21
      }
      if (this.screenHeight === 2732) {
        return 21
      }
      return 22
    },

    left() {
      if (this.screenWidth === 1920 && this.position === 'LEFT') {
        return 39
      }
      if (this.screenWidth === 1920 && this.position === 'RIGHT') {
        return 38.6
      }
      if (this.screenWidth === 1364 && this.position === 'LEFT') {
        return 39
      }
      if (this.screenWidth === 1364 && this.position === 'RIGHT') {
        return 38.6
      }
      if (this.screenWidth === 1366 && this.position === 'LEFT') {
        return 39
      }
      if (this.screenWidth === 1366 && this.position === 'RIGHT') {
        return 38.6
      }
      if (this.screenWidth === 1440 && this.position === 'LEFT') {
        return 43.5
      }
      if (this.screenWidth === 1440 && this.position === 'RIGHT') {
        return 31.9
      }
      if (this.screenWidth === 2732 && this.position === 'LEFT') {
        return 52.5
      }
      if (this.screenWidth === 2732 && this.position === 'RIGHT') {
        return 18.5
      }
      if (this.screenWidth === 1280 && this.position === 'LEFT') {
        return 38.9
      }
      if (this.screenWidth === 1280 && this.position === 'RIGHT') {
        return 38.9
      }
      return 38.6
    },

    containerClass() {
      const diopter = this.measures
        .filter((item) => item.diopter == this.diopter.replace('-', ''))
        .slice(0, 1)
        .shift()

      if (this.position === 'LEFT') {
        return `position: absolute; top:${this.top}%; left: ${
          this.left
        }%; width: ${diopter.indexes[this.index]}%; height: 25%;opacity: 0.5;`
      }

      return `position: absolute; top:${this.top}%; right: ${
        this.left
      }%; width: ${diopter.indexes[this.index]}%;height: 25%;opacity: 0.5;`
    },

    imgClass() {
      if (this.position === 'LEFT') {
        return `width:${this.width};height:100%;`
      }

      return `width:${this.width};height:100%;`
    }
  },

  mounted() {
    if (this.position === 'RIGHT') {
      document.getElementById(this.position).style.transform = 'scaleX(-1)'
    }
  }
}
</script>

<style lang="css" scoped>
.st0 {
  fill: #ffffff;
}
.flip-horizontally {
  transform: scaleX(-1);
}
</style>
