<template>
  <div id="app" class="container flex flex-col h-screen justify-between">
    <router-view />
    <PreladImages />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', {
      isLogged: 'isLogged'
    })
  },

  async mounted() {
    const token = await localStorage.getItem('token')
    if (token) {
      this.$router.push('/home').catch(() => {})
    }
  }
}
</script>

<style lang="css" scoped></style>
