export function SET_USER_STATE(state, payload) {
  state.user = payload
  state.isLogged = true
}

export function CLEAR_STATE(state) {
  state.user = null
  state.isLoading = false
  state.isLogged = false
  state.isAdmin = false
  state.isLab = false
  state.isStore = false
  state.isSaleman = false
}

export function SET_LOADING(state, payload) {
  state.isLoading = payload
}

export function SET_USER_PROFILE(state, payload) {
  const admin = payload.roles.map((role) => role === 'admin')[0]
  const lab = payload.roles.map((role) => role === 'lab')[0]
  const store = payload.roles.map((role) => role === 'store')[0]
  const salesman = payload.roles.map((role) => role === 'salesman')[0]

  if (admin) state.isAdmin = true
  if (lab) state.isLab = true
  if (store) state.isStore = true
  if (salesman) state.isSalesman = true
}
