<template>
  <div class="w-full">
    <div
      class="card mb-2"
      v-for="(index, key) in indexes.flat()"
      :key="key"
      @click="setValue(index)"
    >
      {{ index.label.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductIndex',

  computed: {
    ...mapGetters('menus', {
      indexes: 'getIndexes'
    })
  },

  methods: {
    ...mapActions('menus', ['addPanel', 'fetchProducts']),

    ...mapActions('orders', ['setIndex']),

    setValue(val) {
      this.setIndex(val.value)
      this.addPanel({
        ...val,
        action: 'removeIndex'
      })
      this.fetchProducts()
    }
  }
}
</script>
