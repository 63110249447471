<template>
  <figure
    class="image-compare"
    :class="{ full }"
    @mousemove.prevent="onMouseMove"
    @touchstart="onMouseMove($event, true)"
    @touchmove="onMouseMove($event, true)"
    @click="onMouseMove($event, true)"
  >
    <div
      class="image-compare-wrapper"
      :style="{ width: posX + 'px' }"
      v-show="!hideAfter"
    >
      <img :src="after" :alt="after" class="h-screen w-screen" />
    </div>
    <img :src="before" :alt="before" class="h-screen w-screen" />
    <div
      class="image-compare-handle"
      :style="{ left: posX + 'px' }"
      @mousedown.prevent.stop="onMouseDown"
      v-show="!hideAfter"
    >
      <span class="image-compare-handle-icon left w-6">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 8 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
          <g transform="matrix(1,0,0,1,-8,-5.0002)">
            <path
              d="M15.707,17.293L10.414,12C10.414,12 15.707,6.707 15.707,6.707C16.097,6.317 16.097,5.683 15.707,5.293C15.317,4.903 14.683,4.903 14.293,5.293L8.293,11.293C7.902,11.683 7.902,12.317 8.293,12.707L14.293,18.707C14.683,19.097 15.317,19.097 15.707,18.707C16.097,18.317 16.097,17.683 15.707,17.293Z"
              style="fill:white;"
            />
          </g>
        </svg>
      </span>
      <span class="image-compare-handle-icon right w-6">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 8 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
          <g transform="matrix(-1,0,0,1,15.9998,-5.0002)">
            <path
              d="M15.707,17.293L10.414,12C10.414,12 15.707,6.707 15.707,6.707C16.097,6.317 16.097,5.683 15.707,5.293C15.317,4.903 14.683,4.903 14.293,5.293L8.293,11.293C7.902,11.683 7.902,12.317 8.293,12.707L14.293,18.707C14.683,19.097 15.317,19.097 15.707,18.707C16.097,18.317 16.097,17.683 15.707,17.293Z"
              style="fill:white;"
            />
          </g>
        </svg>
      </span>
    </div>
  </figure>
</template>

<script>
export default {
  name: 'VueCompareImage',
  props: {
    before: {
      type: String,
      required: true
    },
    after: {
      type: String,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Object,
      default() {
        return {
          left: 0,
          right: 0
        }
      },
      required: false
    },
    hideAfter: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      width: null,
      height: null,
      pageX: null,
      posX: null,
      isDragging: false,
      allowNextFrame: true,
      unwatch: null
    }
  },
  computed: {
    dimensions() {
      return {
        width: `${this.width}px`,
        height: this.full ? `${this.height}px` : 'auto'
      }
    }
  },
  methods: {
    onResize() {
      this.width = this.$el.clientWidth
      this.height = this.$el.clientHeight
      this.setInitialPosX(this.padding.left + this.padding.right)
    },
    onMouseDown() {
      this.isDragging = true
    },
    onMouseUp(event) {
      event.preventDefault()

      this.isDragging = false
    },
    onMouseMove(event, isDragging = this.isDragging) {
      if (isDragging && this.allowNextFrame) {
        this.allowNextFrame = false
        this.pageX =
          event.pageX ||
          event.targetTouches[0].pageX ||
          event.originalEvent.targetTouches[0].pageX

        window.requestAnimationFrame(this.updatePos)
      }
    },
    updatePos() {
      let posX = this.pageX - this.$el.getBoundingClientRect().left

      if (posX < this.padding.left) {
        posX = this.padding.left
      } else if (posX > this.width - this.padding.right) {
        posX = this.width - this.padding.right
      }

      this.posX = posX
      this.allowNextFrame = true
    },
    setInitialPosX(padding) {
      if (padding >= this.width) {
        console.error('Sum of paddings is wider then parent element!')

        return
      }

      this.posX = (this.width + this.padding.left - this.padding.right) / 2
    }
  },
  created() {
    window.addEventListener('mouseup', this.onMouseUp)
    window.addEventListener('resize', this.onResize)
  },
  mounted() {
    this.onResize()
    this.unwatch = this.$watch(
      () => this.padding.left + this.padding.right,
      (newValue) => this.setInitialPosX(newValue)
    )
  },
  beforeDestroy() {
    this.unwatch()
    window.removeEventListener('mouseup', this.onMouseUp)
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="css" scoped>
.image-compare {
  position: relative;
  margin: 0;
}
.image-compare.full {
  overflow: hidden;
  height: 100%;
  width: 100%;
  flex: 1;
}
.image-compare.full img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-compare img {
  max-width: none;
  display: block;
}
.image-compare-wrapper,
.image-compare-handle {
  bottom: 0;
  position: absolute;
  top: 0;
}
.image-compare-wrapper {
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transform: translateZ(0);
  will-change: width;
}
.image-compare-handle {
  color: #fff;
  background-color: currentColor;
  cursor: ew-resize;
  transform: translateX(-50%) translateZ(0);
  width: 2px;
  z-index: 200000;
  will-change: left;
}
.image-compare-handle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  color: currentColor;
  line-height: normal;
}
.image-compare-handle-icon.left {
  padding-right: 10px;
  transform: translate(-100%, -50%);
}
.image-compare-handle-icon.right {
  padding-left: 10px;
  transform: translate(0, -50%);
}
</style>
