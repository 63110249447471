<template>
  <div>
    <ProductCompare />
    <div
      class="h-full bg-black w-1/4 fixed z-50 bg-opacity-80 text-white flex-col flex-grow md:flex-row"
    >
      <div class="flex justify-around h-14 items-center">
        <OptHomeButton />
        <OptResetOrderButton />
      </div>
      <div :class="iconsListClass">
        <ProductPanel />
        <ProductType class="mb-2" />
        <ProductManufacturer v-if="type && !manufacturer" class="mb-2 mt-2" />
        <ProductTechnology
          v-if="manufacturer && !technology"
          class="mb-2 mt-2"
        />
        <ProductColor v-if="!color && technology" class="mb-2 mt-2" />
        <ProductIndex
          v-if="indexes.length > 0 && selectedColor >= 0 && !index"
          class="mb-2"
        />
        <ProductList v-if="index" @input="setCover" class="mb-2" />
        <ProductFrame v-if="index && product" class="mb-2" />
        <ProductTreatment v-if="product && typeId !== 5" />
        <div
          class="card text-right pr-4"
          style="margin-top:0"
          v-if="index && product"
        >
          <button
            v-if="product"
            class="float-left text-white border-white border-2 rounded-full border-solid px-2"
            @click="addCompare"
          >
            gravar e comparar
          </button>
          <small>total:</small> <br />
          <div class="text-2xl">
            {{ total }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-screen w-screen"
      :style="
        `background: url(${cover});
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;`
      "
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Budget',

  data: () => ({
    menus: [],
    cover: `https://siou.nyc3.digitaloceanspaces.com/img/budget.jpg`,
    iconsListClass: {
      'h-screen': true,
      'px-4': true,
      'flex-5': true,
      flex: true,
      'flex-col': true,
      'items-center': true,
      'space-y-4': false,
      'overflow-y-auto': true
    }
  }),

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder' }),
    ...mapGetters('menus', {
      colors: 'getColors',
      loading: 'getLoader',
      apis: 'getApis',
      products: 'getProducts',
      indexes: 'getIndexes',
      treatments: 'getTreatments',
      hoops: 'getHoops',
      panels: 'getPanels',
      compare: 'getCompare'
    }),

    selectedColor() {
      return this.order.color_id
    },

    manufacturer() {
      return !!this.order.manufacturer_id
    },

    typeId() {
      return this.order.type_id
    },

    type() {
      return !!this.order.type_id
    },

    technology() {
      return !!this.order.technology_id
    },

    index() {
      return !!this.order.index_id
    },

    color() {
      return !!this.order.color_id
    },

    treatment() {
      return !!this.order.treatment_id
    },

    hoop() {
      return !!this.order.hoop_id
    },

    product() {
      return !!this.order.product_id
    },

    total() {
      let total = 0
      const { product_id, treatment_id, hoop_id } = this.order
      const product = this.products.filter(
        (item) => item.value === product_id
      )[0]
      const treatment = this.treatments
        .flat()
        .filter((item) => item.value === treatment_id)[0]

      if (hoop_id) {
        const hoop = this.hoops
          .flat()
          .filter((item) => item.value === hoop_id)[0]
        total = total + hoop.price
      }

      if (product) {
        total = total + product.price
      }

      if (treatment) {
        total = total + treatment.price
      }

      return `10x de ${this.currency(total / 10)}`
    }
  },

  methods: {
    ...mapActions('menus', ['fetchApis', 'fetchTreatment']),

    ...mapActions('orders', ['setSendTo']),

    slugfy(str) {
      str = str.replace(/^\s+|\s+$/g, '')
      str = str.toLowerCase()
      var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
      var to = 'aaaaaeeeeiiiioooouuuunc------'

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')

      return str
    },

    setCover(val) {
      let pieces = val.label.split('-')
      let string = this.slugfy(`${pieces[0]}-${pieces[1]}`)
      this.cover = `https://siou.nyc3.digitaloceanspaces.com/img/${string}.jpg`
    },

    addCompare() {
      if (this.compare.length < 3) {
        let panels = JSON.parse(JSON.stringify(this.panels))
        panels.push({
          label: 'Total',
          value: this.total
        })
        this.$store.commit('menus/SET_COMPARE', panels)
      }
    },

    currency(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    }
  },

  mounted() {
    this.fetchTreatment()
  }
}
</script>

<style lang="scss" scoped></style>
