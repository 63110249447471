import axios from '../../boot/axios'

export function login({ commit }, payload) {
  commit('SET_LOADING', true)
  axios
    .post('/auth/login', payload)
    .then(({ data }) => {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('logo', data.store.logo)
      commit('SET_USER_STATE', data)
      commit('SET_USER_PROFILE', data)
      this._vm.$router.push('/home')
    })
    .catch(() => {})
    .finally(() => commit('SET_LOADING', false))
}

export function getUserData({ commit }, payload) {
  commit('SET_USER_STATE', payload)
  commit('SET_USER_PROFILE', payload)
}
