<template>
  <div>
    <button @click="toggle" v-if="image">
      <img
        :src="
          `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-informacoes.svg`
        "
        style="width: 24px;"
      />
    </button>
    <div class="modal-backdrop" v-if="isOpen" @click="toggle">
      <div class="modal">
        <section class="modal-body">
          <slot name="body">
            <img :src="image" style="width:800px;border-radius: 5px;" />
          </slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OptInfoButton',

  data: () => ({
    isOpen: false
  }),

  computed: {
    ...mapGetters('ui', {
      image: 'infoImage'
    })
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="css" scoped>
button {
  width: 30px;
}
</style>
