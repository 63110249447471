<template>
  <button @click="goHome">
    <img
      :src="
        `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-inicio.svg`
      "
      alt=""
    />
  </button>
</template>

<script>
export default {
  name: 'OptHomeButton',

  methods: {
    goHome() {
      this.$store.commit('ui/SET_PANELS', {
        menu: true,
        sidebar: false,
        compare: false,
        drawer: false
      })
      this.$store.commit(
        'ui/SET_COVER',
        `https://siou.nyc3.digitaloceanspaces.com/img/home.jpg`
      )
      this.$store.commit('ui/SET_COMPARE', {
        before: null,
        after: null
      })
      if (this.$route.path === '/budget' || this.$route.path === '/measure') {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style lang="css" scoped>
button {
  width: 30px;
}
</style>
