<template>
  <div
    class="flex-col text-white h-4/5"
    style="border: 1px solid rgb(82 82 82 / 37%)"
  >
    <!-- <div class="fadetop absolute h-12 w-20 z-50 top-0"></div> -->
    <div class="h-full text-center overflow-y-scroll overflow-x-hidden barra">
      <div
        v-for="(diopter, idx) in diopters"
        :key="idx"
        @click="setDiopter(diopter, idx)"
      >
        <div
          :class="
            `text-center item text-lg  cursor-pointer ${
              diopter.active ? 'ativo' : ''
            }`
          "
        >
          {{ diopter.value }}
        </div>
      </div>
    </div>
    <!-- <div class="fadeBottom absolute h-12 w-20 z-50 bottom-0"></div> -->
  </div>
</template>

<script>
import diopters from '../../data/diopters'
export default {
  name: 'DiopterChooser',

  data: () => ({
    diopters
  }),

  methods: {
    setDiopter(val, idx) {
      this.diopters.forEach((item) => (item.active = false))
      this.diopters[idx].active = true
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="css" scoped>
.fadetop {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0) 0%,
    rgb(0 0 0 / 65%) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}
.fadeBottom {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 39%) 0%,
    rgb(0 0 0 / 65%) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}

.item {
  text-align: center;
  border: 1px solid none;
  width: 80px;
}
.ativo {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}
.barra::-webkit-scrollbar {
  width: 0;
}

.barra::-webkit-scrollbar-track {
  background: none;
}

.barra::-webkit-scrollbar-thumb {
  background-color: none;
  border: none;
}
</style>
