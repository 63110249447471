import axios from 'axios'
import iziToast from 'izitoast'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://api.siou.csmd.local/api'
    : 'https://api.siou.csmd.com.br/api'

const options = {
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

const axiosInstance = axios.create(options)

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.url !== '/auth/login') {
      const userToken = await localStorage.getItem('token')
      config.headers.Authorization = `Bearer ${userToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    const status = error.response.status

    if (status === 400 || status === 402 || status === 403 || status === 422) {
      const message = Object.values(error.response.data.errors)
        .flat()
        .join('\n')

      iziToast.show({
        title: 'Opss...',
        color: 'red',
        position: 'topLeft',
        message
      })
    }

    if (status >= 500 || status === 401) {
      localStorage.clear()
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
