<template>
  <div class="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <loading
        :active.sync="loading"
        :can-cancel="true"
        :is-full-page="true"
      ></loading>
      <div>
        <OptLogo class="mx-auto" />
      </div>
      <input type="hidden" name="remember" value="true" />
      <div class="shadow-sm">
        <div>
          <label for="email" class="sr-only">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            autocomplete="off"
            required
            class="px-3 py-2 relative block w-full border border-gray-300 rounded-md mb-2"
            placeholder="Email"
            v-model="user.email"
          />
        </div>
        <div>
          <label for="password" class="sr-only">Senha</label>
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="off"
            required
            class="px-3 py-2 relative block w-full border border-gray-300 rounded-md mb-2"
            placeholder="Senha"
            v-model="user.password"
          />
        </div>
      </div>

      <div>
        <OptButton @click.native="doLogin" :text="'ACESSAR'" />
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <label for="remember_me" class="ml-2 block text-sm text-gray-900">
            <input type="checkbox" id="remember_me" v-model="user.remember" />
            Manter conectado
          </label>
        </div>
        <div class="text-sm text-right">
          <button
            class="font-medium text-green-400 hover:text-green-500"
            style="color: rgb(37 108 98)"
          >
            Esqueceu a senha?
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/boot/axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'LoginForm',

  components: {
    Loading
  },

  data: () => ({
    loading: false,
    user: {
      email: null,
      password: null,
      remember: null
    }
  }),

  methods: {
    doLogin() {
      this.loading = true
      axios
        .post('/auth/login', this.user)
        .then(({ data }) => {
          localStorage.setItem('token', data.access_token)
          if (data.store) {
            localStorage.setItem('logo', data.store.logo)
          }
          localStorage.setItem('user', JSON.stringify(data))
          this.$store.commit('auth/SET_USER_STATE', data)
          this.$store.commit('auth/SET_USER_PROFILE', data)
          this.$router.push('/home')
        })
        .catch(() => {})
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped></style>
