export function SET_SIDEBAR(state, payload) {
  state.sidebar = payload
}

export function SET_LOADING(state, payload) {
  state.isLoading = payload
}

export function SET_ICONS(state, payload) {
  state.icons = payload
}
export function SET_GALLERY(state, payload) {
  state.gallery = payload
}
export function SET_INFO_IMAGE(state, payload) {
  state.info_image = payload
}

export function SET_MENUS(state, payload) {
  state.menus = payload
}

export function SET_BEFORE_MENUS(state, payload) {
  state.before_menus = payload
}

export function SET_AFTER_MENUS(state, payload) {
  state.after_menus = payload
}
export function SET_COMPARE(state, payload) {
  state.compare = payload
}

export function SET_COVER(state, payload) {
  state.cover = payload
}

export function SET_PANELS(state, payload) {
  state.panels = payload
}

export function INCREMENT_STEP(state) {
  const step = state.step + 1
  state.step = step
}

export function DECREMENT_STEP(state) {
  const step = parseInt(state.step) - 1
  state.step = step

  if (state.step <= 0) {
    state.panels = {
      menu: true,
      sidebar: false,
      compare: false,
      drawer: false
    }
  }
}
