const diopters = [
  { value: '-10', active: false },
  { value: '-9', active: false },
  { value: '-8', active: false },
  { value: '-7', active: false },
  { value: '-6', active: false },
  { value: '-5', active: false },
  { value: '-4', active: false },
  { value: '-3', active: false },
  { value: '-2', active: false },
  { value: '-1', active: false },
  { value: '0', active: true },
  { value: '1', active: false },
  { value: '2', active: false },
  { value: '3', active: false },
  { value: '4', active: false },
  { value: '5', active: false },
  { value: '6', active: false },
  { value: '7', active: false },
  { value: '8', active: false },
  { value: '9', active: false },
  { value: '10', active: false }
]

export default diopters
