import Budget from '../views/Budget.vue'
import Measure from '../views/Measure.vue'
import Home from '../views/Home.vue'
import LoginView from '../views/Login/LoginView.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/budget',
    name: 'Budget',
    component: Budget
  },
  {
    path: '/measure',
    name: 'Measure',
    component: Measure
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
