import { render, staticRenderFns } from "./OptHomeButton.vue?vue&type=template&id=335d1910&scoped=true&"
import script from "./OptHomeButton.vue?vue&type=script&lang=js&"
export * from "./OptHomeButton.vue?vue&type=script&lang=js&"
import style0 from "./OptHomeButton.vue?vue&type=style&index=0&id=335d1910&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335d1910",
  null
  
)

export default component.exports