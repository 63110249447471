<template>
  <div
    class="flex absolute top-0 w-3/4 flex-grow h-screen"
    style="margin-left:25%"
    v-if="panels.length > 0"
  >
    <div
      v-for="(comp, k) in panels"
      :key="k"
      class="w-2/6 bg-black bg-opacity-70 px-2"
    >
      <div class="card mt-3">
        <div v-for="(com, j) in comp" :key="j">
          <p v-if="com.label !== 'Total'" v-html="com.label" class="mb-2"></p>
          <p v-if="com.label === 'Total'" class="mb-2 text-right pr-3 text-xl">
            {{ com.value }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="absolute z-50 bottom-10 w-full flex"
      v-if="panels.length > 0 && openEmail"
    >
      <div
        class="p-10 bg-black opacity-80 items-center mx-auto rounded-md text-white w-2/4 backdrop-blur-3xl"
      >
        <input
          type="text"
          v-model="name"
          placeholder="Nome"
          class="bg-black border-white border-b-2 p-2 w-full"
        />
        <br />
        <input
          type="text"
          v-model="email"
          placeholder="Email"
          class="bg-black border-white border-b-2 p-2 mt-2 w-full"
        />
        <br />
        <button
          class=" text-white border-white border-2 rounded-full border-solid px-2 py-2 w-full mt-10"
          @click="sendEmail"
        >
          {{ loading ? 'ENVIANDO...' : 'ENVIAR' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/boot/axios'

export default {
  name: 'ProductCompare',

  data: () => ({
    openEmail: false,
    loading: false,
    name: null,
    email: null
  }),

  watch: {
    panels(val) {
      if (val.length > 0) {
        this.openEmail = true
      } else {
        this.openEmail = false
      }
    }
  },

  computed: {
    ...mapGetters('menus', {
      panels: 'getCompare'
    })
  },

  methods: {
    sendEmail() {
      this.loading = true
      axios
        .post('/orders/compare', {
          name: this.name,
          email: this.email,
          data: this.panels
        })
        .then(({ data }) => {
          alert(data.msg)
          this.openEmail = false
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped></style>
