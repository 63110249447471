<template>
  <div class="h-screen w-screen">
    <div
      class="h-screen w-screen absolute"
      :style="
        `background: url(${hastes});
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;`
      "
    ></div>
    <div
      class="h-screen w-screen"
      :style="
        `background: url(${cover});
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;`
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CoverImages',

  data: () => ({
    cover: `https://siou.nyc3.digitaloceanspaces.com/img/app_lente_index_base.jpg`,
    hastes: `https://siou.nyc3.digitaloceanspaces.com/img/app_lente_index_hastes.png`
  })
}
</script>

<style lang="scss" scoped></style>
