<template>
  <div class="text-center">
    <a href="#" @click.prevent.stop="logOut">
      <img
        :src="
          `https://siou.nyc3.digitaloceanspaces.com/img/buttons/icones-botao-fechar.svg`
        "
        alt=""
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'OptLogoffButton',

  props: {
    text: {
      type: String,
      default: 'Sair'
    }
  },

  methods: {
    async logOut() {
      await localStorage.clear()
      this.$store.commit('auth/CLEAR_STATE')
      this.$router.push('/').catch(() => {})
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }
}
</script>

<style lang="css" scoped>
a {
  display: block;
  width: 30px;
}
</style>
