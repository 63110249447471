import axios from '../../boot/axios'

const getUrls = require('get-urls')

function preloadImages(array) {
  if (!preloadImages.list) {
    preloadImages.list = []
  }
  var list = preloadImages.list
  for (var i = 0; i < array.length; i++) {
    var img = new Image()
    img.onload = function() {
      var index = list.indexOf(this)
      if (index !== -1) {
        list.splice(index, 1)
      }
    }
    list.push(img)
    img.src = array[i]
  }
}

export function fetchInterface({ commit }, payload) {
  commit('SET_LOADING', true)
  axios
    .get('/icons', payload)
    .then(({ data }) => {
      commit('SET_ICONS', data.data)
      let urls = JSON.stringify(data.data)
      let results = getUrls(urls)
      preloadImages(results)
    })
    .catch(() => {})
    .finally(() => commit('SET_LOADING', false))
}

export function setMenus({ commit }, payload) {
  commit('SET_MENUS', payload)
}
