export default function() {
  return {
    user: null,
    isLoading: false,
    isLogged: false,
    isAdmin: false,
    isLab: false,
    isStore: false,
    isSaleman: false
  }
}
