export function getSidebar(state) {
  return state.sidebar
}

export function getMenus(state) {
  return state.menus
}

export function isLoading(state) {
  return state.isLoading
}

export function getGallery(state) {
  return state.gallery
}
export function infoImage(state) {
  return state.info_image
}

export function getIcons(state) {
  return state.icons
}

export function getBeforeMenus(state) {
  return state.before_menus
}

export function getCompareImages(state) {
  return state.compare
}

export function getCoverImage(state) {
  return state.cover
}

export function getPanels(state) {
  return state.panels
}
