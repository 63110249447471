<template>
  <div class="imagePreloader">
    <img v-for="(img, idx) in images" :src="img" :key="idx" />
  </div>
</template>

<script>
import axios from '@/boot/axios'

export default {
  name: 'PreloadImages',

  data: () => ({
    images: []
  }),

  methods: {
    async getImages() {
      const { data } = await axios.get('/icons/images')
      this.images = data
    }
  },

  mounted() {
    this.getImages()
  }
}
</script>

<style lang="css" scoped>
.imagePreloader {
  position: absolute;
  z-index: -1;
  display: none;
}
</style>
